
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from 'vuex';
import ClientPicker from "@/components/ClientPicker.vue";
const axios = require("axios").default;

export default defineComponent({
	data()
	{
		return {
			selectedClient: [],
			lineItems: [{
				id: 0,
				name: null,
				description: null,
				price: null,
				amount: null,
				discountPercent: null,
				discountValue: null
			}]
		}
	},
	methods: {
		...mapMutations([
			"setLoading",
			"setLoadingMessage"
		]),
		createOperation()
		{
			if (this.selectedClient == null ||
				this.selectedClient.length == 0 ||
				this.lineItems.length == 0)
			{
				return;
			}

			this.setLoadingMessage("Creating new operation");
			this.setLoading(true);

			let promises : Promise<void>[] = [];
			this.selectedClient.forEach(client => promises.push(this.createOperationForClient(client)));
			Promise.all(promises)
				.then((result: any) =>
				{
					console.log(result);
					this.selectedClient.splice(0);
					this.clearLineItems();
					this.setLoading(false);

					// clear client picker
					this.getMessageBus.emit('ClientPicker.clear');
				})
				.catch((error: any) =>
				{
					console.log("NDF error", error);
					alert("Creation of operation failed");
					this.setLoading(false);
				});
		},
		async createOperationForClient(client : any) : Promise<void>
		{
			// @ts-ignore
			let payload = {clientId: client, operations: this.lineItems};
			axios.post(process.env.VUE_APP_HOST + "/documents/new", payload);
		},
		clearLineItems()
		{
			this.lineItems = [{
					id: 0,
					name: null,
					description: null,
					price: null,
					amount: null,
					discountPercent: null,
					discountValue: null
				}];
		},
		addNewLineItem()
		{
			let id: number = this.lineItems[this.lineItems.length - 1].id;
			this.lineItems.push({id: ++id, name: null, description: null, price: null, amount: null, discountPercent: null, discountValue: null});
		},
		removeLineItem(id: number): void
		{
			if (this.lineItems.length <= 1)
			{
				return;
			}

			let indexOfObject = this.lineItems.findIndex(obj => obj.id == id);
			if (indexOfObject >= 0)
			{
				this.lineItems.splice(indexOfObject, 1);
			}
		}
	},
	computed: {
		...mapGetters(["getMessageBus"])
	},
	components: {
		ClientPicker
	}
});
